import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/mousewheel";
import "swiper/css/autoplay";

import "./styles.scss";

import Picture1 from "@/assets/images/mainBanners/new-product-600-soles.png";
import Picture2 from "@/assets/images/mainBanners/desembolsos.jpg";

const banners = [
  {
    src: Picture1,
    alt: "Desembolsos",
  },
  {
    src: Picture2,
    alt: "Desembolsos",
  },
];

const Carousel = () => {
  return (
    <div className="main-carousel-block">
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Mousewheel]}
        grabCursor={true}
        loop={true}
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <span className="main-carousel-block__slide">slide - {index}</span>
            <img src={banner.src} alt={banner.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
